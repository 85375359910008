<template>
  <div class="content content3 flex-column" style="padding: 10px">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-input v-model="searchUserValue.coding" placeholder="请输入机械编号" size="small" style="width:180px;margin-right:15px;" clearable></el-input>
        <el-input v-model="searchUserValue.departmentName" placeholder="请输入项目名称" size="small" style="width:180px;margin-right:15px;" clearable></el-input>
      </div>
      <div class="search">
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table :data="tableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center" label="机械编号" prop="coding"></el-table-column>
        <el-table-column align="center" prop="departmentName" label="项目名称"></el-table-column>
        <el-table-column align="center" label="台班数量" prop="machineTeamNum"></el-table-column>
        <el-table-column align="center" label="照片" prop="location">
          <template slot-scope="{ row }">
            <span v-if="!row.photoUrl"></span>
            <el-image
                v-else
                style="width: 100px; height: 100px"
                :src="strToImg(row.photoUrl)[0]"
                :preview-src-list="strToImg(row.photoUrl)">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="ListTotal > 10"
          background
          :current-page.sync="searchUserValue.pageCurrent"
          :page-size="searchUserValue.pageSize"
          :total="ListTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeCheckData"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Apis from "@/views/menus/api/security-check";
import {mapGetters} from "vuex";
export default {
  name: "UseRecord",

  components: {},

  props: {},

  data() {
    return {
      searchUserValue: {
        departmentName: '',
        coding: '',
        machineType: 1,
        pageCurrent: 1,
        pageSize: 10
      },
      ListTotal: 0,
      tableData:[],
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem']),
    departmentType(){
      return this.optionItem.departmentType
    },
    isProject(){
      return this.optionItem && this.optionItem.departmentType == 'project';
    },
    // 是否为集团人员
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    }
  },

  watch: {
    companyId() {
      this.getSecurityList()
    }
  },

  mounted() {
    this.getSecurityList()
  },

  methods: {
    // init() {
    // }
    async getSecurityList() {
      if (!this.companyId) return this.getSecurityList.async
      const params = {
        departmentId: this.optionItem.departmentId,
        departmentType: this.optionItem.departmentType,
        ...this.searchUserValue
      }
      const data = await Apis.largeMachineRecord(params)
      this.tableData = data.list
      this.ListTotal = data.total
    },
    async handleSizeChangeCheckData(val) {
      this.searchUserValue.pageCurrent = val
      this.getSecurityList().async
    },
    search(){
      this.searchUserValue.pageCurrent = 1
      this.getSecurityList().async
    },
    strToImg(str) {
      if (str) return str.split(',')
      else return []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
